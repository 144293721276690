import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import loadable from '@loadable/component';
import removeTrailingSlash from '../utils/remove-trailing-slash';

const SubMenu = loadable(() => import('../components/subMenu'));
const Grid = loadable(() => import('../components/grid'));
const CardWithoutImage = loadable(() => import('../components/card-without-image'));

const Category = ({ location, data }) => {
  const { categories, projects } = data;
  const title = categories.edges.find(
    item => removeTrailingSlash(item.node.fields.slug) === `/projects${decodeURIComponent(location.pathname)}`
  );
  const metaData = {
    title: title.node.frontmatter.title,
    description: title.node.frontmatter.description
  };
  return (
    <Layout metaData={metaData}>
      <SubMenu items={categories.edges} location={location.pathname} />
      <Grid mode="dark">
        {projects.edges.map((item, i) => {
          const project = item.node.frontmatter;
          return (
            <CardWithoutImage
              key={`key${i}`}
              title={project.title}
              bg={project.coverImage ? project.coverImage.childImageSharp.fluid.src : null}
              url={item.node.fields.slug.slice(9, item.node.fields.slug.length - 1)}
            />
          );
        })}
      </Grid>
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query($slug: String!) {
    categories: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: true } } }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            index
            title
          }
          fields {
            slug
          }
        }
      }
    }
    category: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
    projects: allMarkdownRemark(
      filter: { fields: { slug: { regex: $slug } }, frontmatter: { category: { ne: true } } }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            category
            coverImage {
              ...mediumImage
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
